import React from 'react';
import { TranslateFunction } from 'react-utilities';
import FavoritesStats from './FavoritesStats';

export function FavoritesButton({
  isFavorited,
  favoriteCount,
  onFavoriteChange,
  translate
}: {
  isFavorited: boolean;
  favoriteCount: number | undefined;
  onFavoriteChange: () => void;
  translate: TranslateFunction;
}): JSX.Element {
  return (
    <li className='favorite-button-container'>
      <div
        className='tooltip-container'
        title={`${
          isFavorited ? translate('ActionRemoveFromFavorites') : translate('ActionAddToFavorites')
        }`}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <a id='toggle-favorite' onClick={onFavoriteChange}>
          <React.Fragment>
            <FavoritesStats favoriteCount={favoriteCount} />
          </React.Fragment>
          <div id='favorite-icon' className={`icon-favorite ${isFavorited ? 'favorited' : ''}`} />
        </a>
      </div>
    </li>
  );
}

export default FavoritesButton;
