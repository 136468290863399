import { EnvironmentUrls, CurrentUser } from 'Roblox';
import { httpService } from 'core-utilities';

export const FavoritesButtonService = {
  getCurrentFavoriteCount: async (id: number, itemType: string): Promise<number> => {
    const { data } = await httpService.get<number>({
      url: `${EnvironmentUrls.catalogApi}/v1/favorites/${itemType}/${id}/count`,
      retryable: true,
      withCredentials: true
    });

    return data;
  },

  getCurrentFavoriteStatus: async (id: number, itemType: string): Promise<JSON> => {
    const { data } = await httpService.get<JSON>({
      url: `${EnvironmentUrls.catalogApi}/v1/favorites/users/${CurrentUser.userId}/${itemType}/${id}/favorite`,
      retryable: true,
      withCredentials: true
    });

    return data;
  },

  postFavorite: async (id: number, itemType: string): Promise<number> => {
    const { status } = await httpService.post<number>({
      url: `${EnvironmentUrls.catalogApi}/v1/favorites/users/${CurrentUser.userId}/${itemType}/${id}/favorite`,
      retryable: true,
      withCredentials: true
    });
    return status;
  },

  deleteFavorite: async (id: number, itemType: string): Promise<number> => {
    const { status } = await httpService.delete<number>({
      url: `${EnvironmentUrls.catalogApi}/v1/favorites/users/${CurrentUser.userId}/${itemType}/${id}/favorite`,
      retryable: true,
      withCredentials: true
    });
    return status;
  }
};

export default FavoritesButtonService;
